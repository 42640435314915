<template>
  <div class="size-12">
    <template v-for="item in list">
      <p v-if="item.type===0" style="background: rgb(206 206 206);margin: 5px 0;height: 1px;"></p>
      <p v-else-if="item.type===7">检测结束</p>
      <p v-else>
        <span v-if="item.type===2" style="color: #67C23A;">{{item.context}}</span>
        <span v-else-if="item.type===3" style="color: #E6A23C;">{{item.context}}</span>
        <span v-else-if="item.type===4" style="color: #F56C6C;">{{item.context}}</span>
        <span v-else style="color: #909399;">{{item.context}}</span>
      </p>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        default: ()=> {
          return []
        }
      }
    },
    data() {
      return{}
    }
  }
</script>

<style scoped>

</style>
